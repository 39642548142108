import React from 'react';
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Grid} from "gymnast";
import {Responsive} from '../components/Responsive';
import CareerQuizPageLaptop from "./CareerQuizPageLaptop";
import CareerQuizPageMobile from "./CareerQuizPageMobile";

class CareerQuizPageSwitch extends React.Component {
    render() {
        function FullWidthGrid() {
            return (
                <Grid marginTop="S" paddingTop="0">
                    <Responsive displayIn={["Laptop"]}>
                        <CareerQuizPageLaptop/>
                    </Responsive>
                    <Responsive displayIn={["Mobile"]}>
                        <CareerQuizPageMobile/>
                    </Responsive>
                    <Responsive displayIn={["Tablet"]}>
                        <CareerQuizPageLaptop/>
                    </Responsive>
                </Grid>
            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default CareerQuizPageSwitch;
