import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css';
import '../css/Fonts.css';
import '../css/Parallax.css';
import '../css/Size.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import Footer from "../components/Footer";
import image from "../resources/images/chris-lawton-5IHz5WhosQE-unsplash.jpg";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";
import {PopupButton} from "@typeform/embed-react";

const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class CareerQuizPageMobile extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={image} strength={500}>
                                <div style={{height: 700, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>

                                            <Col size={12} style={style.SMALL}>
                                                <Card className='width100 border-0 no-background'>
                                                    <CardText className='CormorantGaramondWithoutSize'>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <h1 style={{
                                                            fontSize: '56px',
                                                            color: "black",
                                                            fontWeight: "bold"
                                                        }}>Career Quiz</h1>
                                                    </CardText>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>
                            <Col style={style.SMALL}/>
                            <Col>
                            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                                <CardText>
                                    <h3 className='playfair_Display '
                                        style={{fontSize: '20px', color: "black", fontWeight: 'bold'}}>
                                        <Fade left>
                                            <div>
                                                Discover Your Career Change Readiness in Just 2
                                                Minutes!
                                            </div>
                                        </Fade>
                                    </h3>
                                    <h3 className='playfair_Display '
                                        style={{fontSize: '12px', color: "black"}}>
                                        <Fade left>
                                            <div>
                                                Are you thinking about making a career change or considering your next professional move?
                                                Take this short quiz and find out where you stand on the career change spectrum.
                                            </div>
                                        </Fade>
                                    </h3>
                                </CardText>
                            </Card>
                            </Col>
                            <OnceHubPopUpWidget/>
                            <Col size={12}>
                                <Card className=' width100 border-0' style={{
                                    backgroundColor: '#FFFFFF'
                                }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                        color: "black", textAlign: "left",
                                        width: "100%",
                                    }}>
                                        <h2 className='playfair_Display'
                                            style={{fontSize: '14px', fontStyle: 'italic', fontWeight:'bold'}}>What is the Career Readiness Quiz?</h2>
                                        <CardText className='playfair_Display' style={{fontSize: '12px'}}>
                                            The quiz is designed to help professionals like you assess your readiness for a career shift.
                                            Whether you’re eager to make the leap or just exploring the idea, this quick, insightful quiz
                                            will give you a clear snapshot of where you are and help you gain confidence in your next
                                            steps.
                                        </CardText>
                                        <br/>
                                        <h2 className='playfair_Display'
                                            style={{fontSize: '14px', fontStyle: 'italic', fontWeight:'bold'}}>Why Take the Quiz?</h2>
                                        <CardText className='playfair_Display' style={{fontSize: '12px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '12px', fontWeight: 'bold'}}>-Quick Insight: </h4>
                                            In under two minutes, you’ll have a better understanding of your career
                                            change readiness.
                                        </CardText>
                                        <CardText className='playfair_Display' style={{fontSize: '12px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '12px', fontWeight: 'bold'}}>-Personalised Advice: </h4>
                                            Receive tailored suggestions based on your current career stage and
                                            goals.
                                        </CardText>
                                        <br/>
                                        <h2 className='playfair_Display'
                                            style={{fontSize: '14px', fontStyle: 'italic', fontWeight:'bold'}}>Who is it for?</h2>
                                        <CardText className='playfair_Display' style={{fontSize: '12px'}}>
                                            This quiz is perfect for anyone who’s considering a career change or professional shift, no
                                            matter how far along the journey you are. Whether you&#39;re ready to leap, testing the waters,
                                            or still on the fence, this tool can help provide clarity.
                                        </CardText>
                                        <br/>
                                        <p><h2 className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Ready to find out where you stand?</h2>
                                        </p>
                                    </CardText>
                                    <br/>
                                    <CardText>
                                        <PopupButton id="sYb8zoh0" style={{
                                            backgroundColor: '#85B2EE',
                                            borderColor: '#85B2EE',
                                            color: 'black',
                                            fontSize: '22px',
                                            fontWeight: 'bold',
                                            borderRadius: '5px'
                                        }}  className="playfair_Display">
                                            Take the quiz now
                                        </PopupButton>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default CareerQuizPageMobile;